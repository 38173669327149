import $ from 'jquery';
import sal from 'sal.js';
import 'select2';
import 'select2/dist/css/select2.css';

import 'intersection-observer';
import scrollama from 'scrollama';
import 'jquery.transit';

export default {

	scrollAnimSvg1() {

		var step = $('.Header__container');
		var up10 = step.find('.up-10');
		var up20 = step.find('.up-20');
		var up30 = step.find('.up-30');
		var down10 = step.find('.down-10');
		var down20 = step.find('.down-20');
		var down30 = step.find('.down-30');

	    const scroller = scrollama();

	    function handleStepProgress(response) {

			up20.each(function( index ) {
				$(this).css({ translate: [0, -40*response.progress] });
			});

			up30.each(function( index ) {
				$(this).css({ translate: [0, -60*response.progress] });
			});

		}

		scroller
		  .setup({
		    step: '.Header__container',
		    progress: true,
		    //debug: true,
		    offset: 0.1
		  })
		  .onStepProgress(handleStepProgress);


		window.addEventListener('resize', scroller.resize);
	},

	scrollAnimSvg2() {

		var step = $('.Section1');
		var up20 = step.find('.up-20');
		var up30 = step.find('.up-30');
		var down10 = step.find('.down-10');

	    const scroller = scrollama();

	    function handleStepProgress(response) {

			up20.each(function( index ) {
				$(this).css({ translate: [0, -40*response.progress] });
			});

			up30.each(function( index ) {
				$(this).css({ translate: [0, -60*response.progress] });
			});

			down10.each(function( index ) {
				$(this).css({ translate: [0, 20*response.progress] });
			}); 

		}

		scroller
		  .setup({
		    step: '.Section1',
		    progress: true,
		    //debug: true,
		    offset: 0.1
		  })
		  .onStepProgress(handleStepProgress);


		window.addEventListener('resize', scroller.resize);
	},

	scrollAnimSvg3() {

		$('.Section2_box').each(function( indexbox ) {

			var indexbox = indexbox+1;

			var step = $('.Section2_box:nth-of-type(' + indexbox  + ')');
			var up10 = step.find('.up-10');

		    const scroller = scrollama();

		    function handleStepProgress(response) {

		    	up10.each(function( index ) {
					$(this).css({ translate: [0, -14*response.progress] });
				}); 

			}

			scroller
			  .setup({
			    step: '.Section2_box:nth-of-type(' + indexbox  + ')',
			    progress: true,
			    //debug: true,
			    offset: 0.1
			  })
			  .onStepProgress(handleStepProgress);


			window.addEventListener('resize', scroller.resize);

		});
	},

	salJs() {

		var salSelector = document.querySelectorAll('[data-sal]');

		if( salSelector.length > 0 ) {

			salSelector.forEach( function(item,index) {

				if( !item.getAttribute('data-sal-duration') ) {
					item.setAttribute('data-sal-duration', 1000);
				}

			});

			const salObject = sal({
				threshold: 0.4,
			});

			salObject.enable();

		}

	},

	openTab(evt, tabName) {

		var i, tabcontent, tablinks;

		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].className = tabcontent[i].className.replace(" active", "");
		}

		tablinks = document.getElementsByClassName("tablink");
		for (i = 0; i < tablinks.length; i++) {
		tablinks[i].className = tablinks[i].className.replace(" active", "");
		}

		document.getElementById(tabName).className += " active";
		evt.currentTarget.className += " active";
	},

	init() {

		var t = this;

	    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
		    anchor.addEventListener('click', function (e) {
		        e.preventDefault();

		        $('html ,body').animate({'scrollTop': $(this.getAttribute('href')).offset().top}, 'smooth');
		    });
		});


		document.querySelectorAll('button.tablink').forEach(anchor => {
		    anchor.addEventListener('click', function (e) {
		        e.preventDefault();
		        t.openTab(e, this.dataset.tab);
		    });
		});

		t.salJs();
		t.scrollAnimSvg1();
		t.scrollAnimSvg2();
		t.scrollAnimSvg3();

		$(document).ready(function() {
		    $('.form__inputSelect').select2({
		    	placeholder: 'Select state',
  				allowClear: true
		    });
		});




		var form = document.querySelector('.Contact__form');

		if( form ) {

			var submitButton = form.querySelector('button[type=submit]');
			var formsent = false;
			var fields = form.querySelectorAll('.form__input');
			var errors = 0;

			submitButton.addEventListener('click',function(e) {
				e.preventDefault();
				if( !submitButton.classList.contains('disabled') && !formsent ) {

					errors = 0;

					var reCaptchaValue = document.getElementById('g-recaptcha-response').value;

					fields.forEach( function( inputContainer, index ) {

						console.log(inputContainer);

						if( inputContainer.dataset.type == 'checkbox' ) {

							var input = inputContainer.querySelector('input');

							if( input.checked == false ) {

								inputContainer.classList.add('error');
								errors++;

							} else {

								inputContainer.classList.remove('error');

							}

						}

						if(inputContainer.dataset.type == 'text' || inputContainer.dataset.type == 'email') {

							var input = inputContainer.querySelector('input');

							if( !input.value ) {
								inputContainer.classList.add('error');
								errors++;
							} else {
								inputContainer.classList.remove('error');
							}

						}

						if(inputContainer.dataset.type == 'email') {

							var input = inputContainer.querySelector('input');

							if( input.value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

								inputContainer.classList.remove('error');

							} else {

								inputContainer.classList.add('error');
								errors++;
							}


						}

						if(inputContainer.dataset.type == 'select') {

							var input = inputContainer.querySelector('select');

							if( !input.value ) {
								inputContainer.classList.add('error');
								errors++;
							} else {
								inputContainer.classList.remove('error');
							}

						}

						if(inputContainer.dataset.type == 'captcha') {

							if( !reCaptchaValue ) {
								inputContainer.classList.add('error');
								errors++;
							} else {
								inputContainer.classList.remove('error');
							}

						}

						if(inputContainer.classList.contains('error') && !inputContainer.querySelector('.form__inputError')) {
							$( inputContainer ).prepend( '<div class="form__inputError">' + inputContainer.dataset.warning + '</div>' );
						}

					});

					if(errors === 0) {

						submitButton.classList.add('disabled');
						submitButton.classList.add('loading');
						$('.form__captcha').fadeOut(300);
						$( submitButton ).prepend( '<div class="spinner"><div></div><div></div><div></div><div></div></div>' );

						var requestData = [];

						fields.forEach( function( inputContainer, index ) {

							var input;

							if(inputContainer.dataset.type == 'text' || inputContainer.dataset.type == 'email') {

								input = inputContainer.querySelector('input');
								requestData.push( input.getAttribute('name')+'='+input.value );

							}

							if(inputContainer.dataset.type == 'select') {

								input = inputContainer.querySelector('select');
								requestData.push( input.getAttribute('name')+'='+input.value );

							}

							$(input).prop('disabled', true);

						});

						requestData.push('g-recaptcha-response=' + reCaptchaValue);

						requestData = requestData.join('&');

						var request = new XMLHttpRequest();

						request.onreadystatechange = function() {

							if (request.readyState == 4) {

								if( request.status == 200 ) {

									formsent = true;

									submitButton.classList.remove('loading');
									submitButton.classList.add('success');
									$(submitButton.querySelector('span')).html(submitButton.dataset.success);

								}
								else {
									form.classList.add('Contact__formError');
									submitButton.classList.remove('loading');

									if(form.classList.contains('Contact__formError') && !form.querySelector('form .Contact__errorMessage')) {
										$( form.querySelector('form') ).append( '<div class="Contact__errorMessage">' + form.dataset.warning + '</div>' );
									}
								}

							}

						};

						request.open('POST', form.dataset.formendpoint );
						request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
						request.send(requestData);

					}

				}
			});

		}
		

	}
};
